.speaking-output-container {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.speaking-output {
    overflow: auto;
    padding: 10px;
    height: 150px;
    width: inherit;
    border-radius: 20px;
    font-size: 1rem;
    resize: none;
    box-sizing: border-box;
    border: 1px solid #c5c5c5;
}

.score-container {
    padding: 10px;
}

.scores {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.score {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
}

.score-circle {
    position: relative;
    width: 70px;
    height: 70px;
    font-size: 20px;
}

.score-circle svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
}

.score-circle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: black;
}

.score-name {
    font-size: 16px;

    .score-description {
        font: inherit;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}
