.finished-header {
  font-size: 3rem;
  font-weight: 700;
}

.info-container.finished {
  text-align: center;
  flex: none;
}

.finished-button {
  width: 100%;
  max-width: 700px;
  padding: 10px;
  border-radius: 20px;
}