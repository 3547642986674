.main-content {
  margin-left: 300px;
  display: flex;
  background-color: #f8f8ff;
  padding: 50px;
  justify-content: center;

  &.no-margin {
    margin-left: 0;
  }

  &.lp {
    gap: 20px
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
}

.horizontal-align-txt {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.true-center {
  display: grid;
  place-items: center;
}

.button {
  border: none;
  background-color: transparent;
  display: flex;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  font: inherit;
  color: black;
  padding: 0;
  padding-block: 0;
  padding-inline: 0;
  transition: 0.2s ease-in-out;

  &.blue {
    color: white;
    background-color: #3a61ff;
    transition: background-color 0.2s ease-in-out;
    border: 2px solid transparent;

    &:hover {
      background-color: white;
      color: #3a61ff;
      border: 2px solid #3a61ff;
    }
  }

  &.generic {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
  }

  .button-txt {
    text-align: center;
    align-self: center;
    display: flex;
  }

  .arrow-wrapper {
    display: grid;
    place-items: center;
    margin-left: 5px;
    transition: transform 0.2s ease-in-out;
    height: 100%;
  }

  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    transition: 0.2s ease-in-out;
    height: 100%;
  }
}

.button:hover {
  cursor: pointer;

  .arrow-wrapper {
    transform: translateX(5px);
  }

  .popup-wrapper {
    transform: scale(1.2);
  }
}

.flag-img {
  margin-right: 10px;
  border-radius: 5px;
  line-height: 1em;
  width: 50px;

  &.big {
    height: 50px;
    width: auto;
    user-select: none;
    line-height: normal;
    margin-right: 0;
  }
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  gap: 5px;

  .txt {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
}

.welcome-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .messages-container {
    padding: 40px;
    box-sizing: border-box;
    background-color: #cfdcff;
    border-radius: 20px;
    margin: 10px;
    flex: 1;

    .welcome-message {
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 20px;
      color: #3a61ff;
    }

    .message {
      font-size: 18px;
    }
  }

  .practice-button-wrapper {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin: 10px;
    flex: 1;

    .practice-btn {
      width: 100%;
      padding: 40px;
      font-weight: 700;
      background-color: #dabeff;
      border-radius: 20px;
      color: #5529ee;
      font-size: 24px;
      border: 3px solid transparent;
      transition: border 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        border: 3px solid #5529ee;
      }
    }
  }
}

.form-btn-container {
  display: flex;
  gap: 5px;

  .vocab-table-btn {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    color: #5529ee;
    background-color: #dabeff;
  }
}

.info-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  .language-settings {
    display: flex;
    flex: 1;
    background-color: #cfdcff;
    border-radius: 20px;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    height: fit-content;
    margin: 10px;

    .language-settings-container {
      transition: scale 0.2s ease-in-out;

      &:hover {
        scale: 0.95;
      }

      .header-container {
        padding: 20px;
      }

      .info-content {
        display: flex;
        height: 100%;
        flex-direction: column;

        .active-exercises {
          flex-direction: column;

          .exercises-container {
            display: flex;
          }
        }

        .info {
          display: flex;
          padding: 20px;
          flex-wrap: wrap;

          .info-txt {
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            margin-right: 10px;
          }

          .exercise {
            padding: 5px;
            font-size: 35px;
          }
        }
      }
    }
  }

  .home-table-container {
    flex: 2 0;
    padding: 10px;
    background-color: #cfdcff;
    border-radius: 20px;
    text-decoration: none;
    color: inherit;
    margin: 10px;

    .table-wrapper.home {
      min-width: 300px;
      height: auto;
      margin: 0;
      max-height: 300px;
    }

    .vocab-add-btn {
      padding: 15px;
      background-color: #a9c0ff;
      border-radius: 20px;
      border: 2px solid transparent;
      transition: border 0.2s ease-in-out;
      color: #002181;

      &:hover {
        cursor: pointer;
        border: 2px solid #5985ff;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-content {
    margin: 0;
    padding: 5px;
  }
  .lp-container {
    .lp-info-container {
      padding: 5px;
    }
  }
}