.answer-input-container {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    .answer-input {
        padding: 10px;
        height: 150px;
        width: inherit;
        border-radius: 20px;
        resize: none;
        box-sizing: border-box;
        border: 1px solid #c5c5c5;
        font: inherit;

        &:focus {
            outline: none;
            border: 1px solid #a2a2a2;
            box-shadow: 0 0 0 1px #a2a2a2;
        }

        &.submitted:focus {
            border: 1px solid #c5c5c5;
        }
    }

    .characters-container {
        display: flex;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;

        .word {
            display: grid;
            place-items: center;
            padding: 7px;
            border-radius: 5px;
        }
    }
}