.welcome-container {
  .add-vocab-button-wrapper {
    display: flex;
    justify-content: center;
    margin: 10px;
    flex: 3;

    .add-vocab-button {
      width: 100%;
      border-radius: 20px;
      font-weight: 600;
      font-size: 24px;
      padding: 10px;
    }
  }

  .practice-button-wrapper {
    .practice-btn {
      font-weight: 600;
      padding: 10px;
      min-width: 200px;
    }
  }
}

.vocab-table-page-wrapper {
  margin: 10px;

  .categories-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 10px;

    .category-square {
      text-align: center;
      border: transparent 3px solid;
      background-color: #dabeff;
      font-weight: 700;
      color: #5529ee;
      padding: 20px;
      border-radius: 15px;

      &:hover {
        border: 3px solid #5529ee;
        transform: scale(1.02);
      }
    }
  }
}

.modal-container {
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 10px;

  .txt-field {
    margin-top: 10px;
    flex: 1;
    margin-bottom: 1rem;
    font: inherit;
    resize: none;
    border-radius: 10px;
    border: 2px solid #e6e6e6;
    padding: 12px;
    min-height: 200px;

    &:focus {
      outline: none;
      border: 2px solid #bdbdbd;
    }
  }

  .submit-vocab-button {
    flex: 3;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 600;
    border-radius: 20px;
  }

  .close-modal-button {
    flex: 1;
    padding: 10px;
    border: 3px solid #3a61ff;
    border-radius: 20px;
    color: #3a61ff;
    font-weight: 600;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #d3dcff;
    }
  }

  .add-words-btn {
    padding: 10px;
    border-radius: 20px;
  }

  .modal-buttons-container {
    display: flex;
    gap: 5px;

    .confirm-button {
      flex: 3;
      padding: 20px;
      margin: 5px;
      border: 3px solid transparent;
      background-color: #3a61ff;
      color: white;
      font-weight: 600;
      border-radius: 20px;
      transition: 0.2s ease-in-out;

      &:hover {
        border: 3px solid #0031ff;
      }
    }
  }

  .cancel-button {
    flex: 1;
    padding: 10px;
    margin: 5px;
    border: 3px solid #3a61ff;
    border-radius: 20px;
    color: #3a61ff;
    font-weight: 600;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #d3dcff;
    }
  }
}

.content-container {
  .alert {
    position: fixed;
    z-index: 999;
    width: calc(1000px - 32px);
    max-width: calc(100% - 32px);
    font: inherit;
    bottom: 20px;
  }
}