.collapsible-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.dropdown-button {
  display: flex;
  max-width: 700px;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 10px;
  background-color: #3a61ff;
  border: 3px solid transparent;
  color: white;
  border-radius: 10px;

  &.active {
    background-color: white;
    color: #3a61ff;
    border: 3px solid #3a61ff;
  }

  &.disabled {
    opacity: 0.5;
    cursor: initial;
  }
}

.add-language {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .dropdown-menu {
    margin-top: 10px;
    background-color: white;
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    border: 1px solid #e7e7e7;

    .dropdown-option {
      padding: 10px;
      width: 100%;
      justify-content: normal;
      border-radius: 10px;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}