.table {

  .vocab-table-wrapper {
    border-radius: 20px;
    background-color: white;
  }

  .home {
    max-height: 300px;

    th {
      font-size: 16px;
    }
  }

  thead {
    background-color: #eeeeee;

    th {
      font: inherit;
      font-size: 18px;
      font-weight: 600;
      width: 25%;
      text-align: center;
      border: 1px solid #eeeeee;
      padding: 10px;

      .arrow-balancer {
        height: 18px;
        width: 18px;
      }
    }
  }

  td {
    font: inherit;
    box-sizing: border-box;
    font-size: 18px;
    border: 1px solid #eeeeee;
    text-align: center;
    padding: 5px;

    .bar {
      width: 7px;
      margin: 1px;
      display: inline-block;
      border-radius: 2px;
    }

    .bar:nth-child(1) {
      height: 10px;
    }

    .bar:nth-child(2) {
      height: 15px;
    }

    .bar:nth-child(3) {
      height: 20px;
    }

    .bar.grey {
      background-color: #cecece;
    }

    .bar.red {
      background-color: #ff5252;
    }

    .bar.yellow {
      background-color: #EED202;
    }

    .bar.green {
      background-color: #00c400;
    }
  }

  .remove-word-td {
    padding: 6px;
    font-size: 16px;

    .generic-btn {
      border-radius: 10px;
      font-weight: 600;
      padding: 8px;
      border: 2px solid transparent;
      box-sizing: border-box;
      width: 100px;

      &.delete-btn {
        color: #b46969;

        &:hover {
          border: 2px solid #b46969;
        }
      }

      &.add-btn {
        color: #3a61ff;

        &:hover {
          border: 2px solid #3a61ff;
        }
      }
    }
  }

  .pagination {
    display: flex;
    position: relative;
    justify-content: right;
    align-items: center;
    padding: 10px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    thead {
      th {
        padding: 0;
      }
    }
    td {
      padding: 0;
    }
  }
}