.submit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  border-radius: 15px;

  .feedback {
    display: flex;
    border-radius: 10px;
    padding: 10px;
    gap: 15px;

    .visual-feedback {
      display: grid;
      place-items: center;
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      border-radius: 50%;
      font-size: 2rem;
      background-color: white;
    }

    .feedback-title {
      display: flex;
      flex-wrap: wrap;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  &.correct {
    background-color: #deffc4;
    color: #48a900;

    .eval {
      background-color: #58cc02;
      color: white;
      border: 2px solid transparent;

      &:hover {
        background-color: transparent;
        border: 2px solid #58cc02;
        color: #58cc02;
      }
    }
  }

  &.incorrect {
    background-color: #ffe0e0;
    color: #ff464b;

    .eval {
      background-color: #ff464b;
      color: white;
      border: 2px solid transparent;

      &:hover {
        background-color: transparent;
        border: 2px solid #ff464b;
        color: #ff464b;
      }
    }
  }

  .sentence-eval-buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;

    .eval {
      flex: 1;
      padding: 10px;
      font-weight: 600;
      border-radius: 20px;
      transition: 0.2s ease-in-out;
    }

    .change-result {
      color: inherit;
      text-decoration: underline;
    }

    .gpt-btn {
      background-color: #19c37d;
      color: white;
      padding: 10px;
      border-radius: 20px;
      transition: 0.2s ease-in-out;
      font-weight: 600;
      border: 2px solid transparent;

      &:hover {
        color: #19c37d;
        background-color: white;
        border: 2px solid #19c37d;
      }
    }
  }
}

.mistake {
  display: inline;
  text-decoration: underline;
}

.submit-btn {
  font-weight: 600;
  padding: 10px;
  border-radius: 20px;

  &.inactive {
    background-color: #d3d3d3;
    color: #a9a9a9;

    &:hover {
      cursor: default;
      background-color: #d3d3d3;
      color: #a9a9a9;
      border: 2px solid transparent;
    }
  }

  &.skip {
    background-color: #ffd700;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #ffdf00;
    }
  }
}

@media (max-width: 768px) {
  .submit-wrapper {
    .feedback {
      .visual-feedback {
        display: none;
      }
    }
  }
}